import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCallAuthKey } from '../../JS/Connector';
import { HideLoder, ShowLoder } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../Components/Popup';

export function TicketDetails() {
  const [schoolname, setschoolname] = useState("");
  const [schoolID, setschoolID] = useState("");
  const [PopupData, setPopupData] = useState('');
  const [Comments, setComments] = useState([]);
  const [TicketDetails, setTicketDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [Alerts, setAlerts] = useState("");
  const pathname = window.location.pathname;
  const pathArray = pathname.split('/');
  const schoolnumber = pathArray[2];
  const ticketnumber = pathArray[3];
  const guid = pathArray[4];
  const [PreviewImg, SetPreviewImg] = useState('')
  const [allData, setAllData] = useState({
    Comment: "",
    Document: null,
    Filename: "",
  });
  const [allowComments, setAllowComments] = useState('')
  var BucketS3Link = process.env.REACT_APP_CDN_KEY;
  useEffect(() => {
    ApiGetCallAuthKey("/schoolDatabyNumber/" + schoolnumber).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setschoolname(responseRs.msg.name);
        setschoolID(responseRs.msg.id);
      }
    });
    GetComment()

  }, []);

  async function GetComment() {
    await ApiGetCallAuthKey("/getAllCommentsById/" + ticketnumber).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs.SupportTicketDetails.img != null) {
            SetPreviewImg(responseRs.SupportTicketDetails.img);
          }
          setComments(responseRs.msg);
          setTicketDetails(responseRs.SupportTicketDetails);
          setAllowComments(responseRs.allowComments)
        }
      }
    });
  }

  function handlePdfFileUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const base64String = event.target.result;
      const base64Value = base64String.split(";base64,").pop();
      setAllData({ ...allData, Document: base64Value, Filename: file.name, Flag: 1 });
    };

    reader.readAsDataURL(file);
  };

  async function SaveSupportTicketCall() {
    var isFormValid = CheckValidation({ formID: 'SupportTicketForm' });
    if (!isFormValid) return;
    setLoading(true);
    var raw = JSON.stringify({
      SchoolID: schoolID,
      TicketNum: ticketnumber,
      UserEmail: null,
      Comment: allData.Comment,
      AssignStaffMember: TicketDetails.AssignedTo,
      Flag: 2,
      Document: allData.Document,
    });
    await ApiPostCallAuthKey("/addCommentsonSupportTicket", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        if (result == "success") {
          setAlerts(<AlertsComp show={true} variant="success" msg={"Comment Successfully."} />);
          setTimeout(() => {
            setAlerts(<AlertsComp show={false} />)
            window.location.reload();
          }, 1500);
        }
        else {
          setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
        }
      }
    });
  }
  return (
    <div>
      {Alerts}
      {loading ? <ShowLoder /> : <HideLoder />}
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className={window.innerWidth >= 767 ? 'm-3 px-1' : ''}>
            <div className={window.innerWidth >= 767 ? 'GridBox p-4 m-auto mx-5' : 'GridBox p-4 m-auto'}>
              <div className="col-12 text-center pb-4">
                <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
              </div>
              <div className='Header mt-2 text-center '>
                <h5 className="mb-0">
                  <>{TicketDetails.type == 1 ? "Technology" : "Maintenance "} Support Ticket Comment(s) of <span className="schoolColorStatus">{schoolname}</span></>
                </h5>
                <img src='/images/supportTicketLine.svg' className='img-fluid ' />
                <div className='row mt-4'>
                  <div className='col-lg-5'>
                    <div className='greyBox'>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Role :  </div>
                        <div className="col-md-8 text-start"> {TicketDetails.role == 2 ? "Teacher" : "Student / Parent"}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Name : </div>
                        <div className="col-md-8 text-start"> {TicketDetails.name || '-'}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4  text-start fw-600 supportticket-fontcolor">Email : </div>
                        <div className="col-md-8 text-start"> {TicketDetails.email || '-'}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Building : </div>
                        <div className="col-md-8 text-start"> {TicketDetails.other_building_name ? TicketDetails.other_building_name : TicketDetails?.building?.name}</div>
                      </div>
                      {TicketDetails.role == 2 && (
                        <div className="p-1 pt-2 pe-0 row">
                          <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Room No. : </div>
                          <div className="col-md-8 text-start"> {TicketDetails.room_name ? TicketDetails.room_name : TicketDetails?.room ? TicketDetails?.room?.name : '-'}</div>
                        </div>
                      )}
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Student No. : </div>
                        <div className="col-md-8 text-start"> {TicketDetails.student_num || '-'}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Issue Type :  </div>
                        <div className="col-md-8 text-start">  {TicketDetails.IssueType || '-'}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Ticket Subject :  </div>
                        <div className="col-md-8 text-start" style={{ wordWrap: "break-word" }}>  {TicketDetails.title || '-'}</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        {TicketDetails.description != null ?
                          <>
                            <div className="text-start fw-600 supportticket-fontcolor">Ticket Description:  </div>
                            <div className="text-start">  {TicketDetails.description || '-'}</div>
                          </>
                          :
                          <>
                            <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Ticket Description:  </div>
                            <div className="col-md-8 text-start">  {TicketDetails.description || '-'}</div>
                          </>
                        }

                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Attached Image :  </div>
                        <div className="col-md-8 text-start">   {TicketDetails.img != null ?
                          <img className='ps-2 cursor-pointer' title='Preview Image' alt="" src={`${BucketS3Link}${TicketDetails.img}`} height="70px" onClick={(e) => { SetPreviewImg(TicketDetails.img); setLgShow(true) }} />
                          :
                          <>-</>
                        }</div>
                      </div>
                      <div className="p-1 pt-2 pe-0 row">
                        <div className="col-md-4 text-start fw-600 supportticket-fontcolor">Attached Video:</div>
                        <div className="col-md-8 text-start cursor-pointer">
                          {TicketDetails.video_path != null ? (
                            <iframe
                              width="200"
                              height="100"
                              src={`${BucketS3Link}${TicketDetails.video_path}`}
                              frameborder="0"
                              allowfullscreen

                            ></iframe>
                          ) : (
                            <>-</>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='col-lg-7'>
                    {Comments.length != 0 ?
                      Comments.map((item, i) => {
                        const user = item.User;
                        return (
                          <div className='boxshaowstyle-forComments mb-3'>
                            <div className='row p-3 align-items-center'>
                              <div className="col-md-1 text-center ">
                                <img src="/images/ProfileIcon.svg" className="img-fluid" />
                              </div>
                              <div className="col-md-11 ">
                                <div className='chatheading text-start fw-600'>{user?.charAt(0)?.toUpperCase() + user?.slice(1)}</div>
                                <div className='chatheadingsubtitle text-start mt-1'>{item.Date} at {item.Time}</div>
                              </div>
                              <div className='col-md-11 offset-md-1 pt-2 chatheading text-start chatcontent'> {item.Comment}  </div>
                              {item.Img != null && (
                                <div className='text-start offset-md-1 pt-3'>
                                  <img src="/images/PicturePreview.svg" className="img-fluid boxshaowstyle cursor-pointer" onClick={(e) => { SetPreviewImg(item.Img); setLgShow(true) }} />
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })
                      :
                      <div className="col-12 text-center boxshaowstyle p-3 mb-3">
                        No Comments Found
                      </div>
                    }
                    {/* add Comments */}
                    {guid != null && allowComments == 1 && (
                      <div id='SupportTicketForm' className="parent addcomments-box-style">
                        <div className='row p-2 align-items-center'>
                          <div className="col-md-1 text-center ">
                            <img src="/images/ProfileIcon.svg" className="img-fluid" />
                          </div>
                          <div className="col-md-11 ">
                            <textarea rows={1} required className="comment-textarea col-12" name="comment"
                              placeholder='Write a comment here' value={allData.Comment}
                              onChange={(e) => setAllData({ ...allData, Comment: e.target.value })}
                            ></textarea>
                            <span className="form-text invalid-feedback text-start">
                              *required
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 text-end pb-3 px-4">
                          {allData.Filename && <label className="pe-4 mt-1" style={{ fontWeight: "600", color: '#3CBBA5' }}>{allData.Filename} </label>}
                          <input
                            type="file"
                            id="UploadFileId"
                            name="myFile"
                            accept=".jpg,.png,.svg,.jpeg"
                            style={{ display: 'none' }} // Hide the file input initially
                            onChange={(e) => handlePdfFileUpload(e)}
                          />
                          <FontAwesomeIcon icon={faPaperclip} className='cursor-pointer' title='Attach Image or Screenshot' onClick={() => document.getElementById('UploadFileId').click()} />
                          <button className="SaveBtn mt-2 ms-4 px-4" onClick={(e) => SaveSupportTicketCall(e)}>Send</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup isshow={lgShow} size={"md"} title={"Preview Image"}
        closePopup={(e) => { setLgShow(false); SetPreviewImg('') }}
        modalBody={<img src={`${BucketS3Link}${PreviewImg}`} style={{ maxHeight: '600px', maxWidth: '100%' }} />}
        visibleSaveBtn={false} />

    </div >
  )
}
