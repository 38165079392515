import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { ManageTicket } from '../ManageTicket';
import RepairDetails from '../../Components/RepairDetails';
import { CreateTicket } from '../CreateTicket';
import { FaqIndex } from '../FAQ';
import { ErrorPage } from '../404Error';
import { ManageParts } from '../ManagePart';
import { TechnologySupportTicket } from '../SupportTicket/Technology';
import { MaintenanceSupportTicket } from '../SupportTicket/Maintenance';
import HelpdeskIndex from '../Helpdesk';
import HelpdeskTicket from '../Helpdesk/HelpdeskTicket';
import Helpdeskrequest from '../Helpdesk/Helpdeskrequest';
export default function Access2() {
    return (
        <Routes>
            <Route exact path="/" element={<ManageTicket />} />
            <Route exact path="/manage-tickets/*" element={<ManageTicket />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route exact path="/manage-parts" element={<ManageParts />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="/technology-support-tickets" element={<TechnologySupportTicket />} />
            <Route exact path="/maintenance-support-tickets" element={<MaintenanceSupportTicket />} />
            <Route exact path="/helpdesk" element={<HelpdeskIndex />} />
            <Route exact path="/add-helpdesk" element={<HelpdeskTicket />} />
            <Route exact path="/helpdesk-requests" element={<Helpdeskrequest />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
