import React, { useContext, useEffect, useState } from 'react'
import { AuditContext } from '.'
import { ApiGetCall, ApiGetCallAuthKey } from '../../JS/Connector';
import { LogInSchoolID } from '../../JS/Common';
import Cookies from 'js-cookie';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../App';

const Dotsloder = () => {
    return (
        <section className="dots-container pb-5">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </section>
    )
}
export default function BuildingRoom() {
    const { BuildingData, RoomData, setRoomData, setBuildingData, setVisibilityDiv } = useContext(AuditContext);
    const { UserData } = useContext(UserContext);

    const [Buildingloading, setBuildingLoading] = useState(false);
    const [RoomLoading, setRoomLoading] = useState(false);
    var Schoolname = UserData.schoolname;
    useEffect(() => {
        getBuildings();
    }, [])

    function getBuildings() {
        ApiGetCallAuthKey("/getBuildingsAndRooms/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setBuildingData({
                    ...BuildingData, AllBuildings: responseRs, BuildingId: 0,
                    BuildingName: ''
                });
                setRoomData({
                    ...RoomData, AllRooms: [],
                    RoomId: 0, RoomName: '', ShowRoomDiv: 'd-none'
                });
            }
        });
    }
    function getRooms(buildingid) {
        ApiGetCallAuthKey("/getBuildingDataById/" + buildingid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setRoomData({
                    ...RoomData, AllRooms: responseRs.msg?.room, RoomId: 0, RoomName: '', ShowRoomDiv: ''
                })
            }
        });
    }
    function Onchangebuilding(value) {
        setRoomData({
            ...RoomData,
            AllRooms: [], RoomId: 0, RoomName: '', ShowRoomDiv: 'd-none'
        })
        if (value == 0) {
            setBuildingData({
                ...BuildingData, BuildingName: "", BuildingId: 0
            });
        } else if (value == "all") {
            setBuildingData({ ...BuildingData, BuildingName: "All Buildings", BuildingId: "all" });
            setRoomData({
                ...RoomData,
                AllRooms: [], RoomId: "all", RoomName: '', ShowRoomDiv: 'd-none'
            })
        } else {
            const selectedBuilding = BuildingData.AllBuildings.find(building => building.id == parseInt(value));
            setBuildingData({
                ...BuildingData, BuildingName: selectedBuilding.name, BuildingId: value
            });
            getRooms(value)
        }
    }
    function handleBuildingButtonClick(buildingId) {
        setBuildingLoading(true);
        Onchangebuilding(buildingId);
        setTimeout(() => {
            setBuildingLoading(false);
        }, 1000);
    };
    function Onchangeroom(value) {
        if (value == 0) {
            setRoomData({
                ...RoomData, RoomName: "", RoomId: 0
            });
        } else if (value == "all") {
            setRoomData({
                ...RoomData, RoomName: "Entire Building", RoomId: "all",
            });
        } else {
            const selectedRoom = RoomData.AllRooms.find(room => room.id == parseInt(value));
            setRoomData({
                ...RoomData,
                RoomName: selectedRoom.name,
                RoomId: value
            })
        }
    }
    function handleRoomButtonClick(roomId) {
        setRoomLoading(true);
        Onchangeroom(roomId);
        setTimeout(() => {
            setRoomLoading(false);
        }, 1000);
    };
    function handleBackButtonClick(flag) {
        if (flag === 'Building') {
            setBuildingData(prevState => ({
                ...prevState, BuildingName: '', BuildingId: 0
            }))
            setRoomData(prevState => ({
                ...prevState, ShowRoomDiv: 'd-none'
            }))
        }
        else {
            setRoomData(prevState => ({
                ...prevState, RoomName: '', RoomId: 0
            }))
        }

    }
    return (
        <>
            <hr className='m-0 mt-2' />
            <div className='repeat-div p-3'>
                <div className="col-md-12">
                    <div className='col-12 font-14 d-flex align-items-center' style={{ color: "#6A6A6A" }}>
                        Welcome to the Audit Room!
                        <FontAwesomeIcon icon={faClipboardList} className='mx-2 byebye-animation' style={{ height: "20px", width: "20px", color: '#3CBBA5' }} />
                    </div>
                    <div className='col-12 mt-2 '>
                        <h4 className='fw-600 mb-0'>{Schoolname}</h4>
                    </div>
                    {BuildingData.AllBuildings.length == 0 ?
                        <div className='col-12 pt-5'>
                            <h4>You currently have no buildings listed for your school. Please add at least one building to begin the audit.</h4>
                        </div>
                        :
                        <>
                            <label style={{ color: "#6A6A6A", fontWeight: "500" }} className='font-13 mt-2'>Choose a building and/or room below to start Auditing inventory from.</label>
                            <div className='row py-3 mt-3'>
                                <div className="col-12">
                                    <div className='col-md-7 questionchatbox'>
                                        <img src='/images/GreenSmallLogo.svg' className='img-fluid questionlogo' />
                                        Please select from which building do you want to start Auditing?
                                    </div>
                                </div>
                                <div className='col-12 my-2'>
                                    {!BuildingData.BuildingName ? (
                                        <div className={`ps-0 d-flex flex-wrap`}>
                                            {BuildingData.AllBuildings?.map((item, i) => (
                                                <div className='py-2 align-items-center mx-2' key={i}>
                                                    <button className="helpDesk-button" onClick={() => handleBuildingButtonClick(item.id)}>
                                                        {item.name}
                                                    </button>

                                                </div>
                                            ))}
                                            <div className='py-2 align-items-center mx-2'>
                                                <button className="helpDesk-button" onClick={() => handleBuildingButtonClick("all")}>
                                                    All Buildings
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {Buildingloading ? (
                                                <div className='float-start mt-3'>
                                                    <Dotsloder />
                                                </div>
                                            ) : (
                                                <div className='float-end'>
                                                    <button className={`helpDesk-button active px-3 py-2 cursor-pointer`}>
                                                        <span className=''>{BuildingData.BuildingName}</span>
                                                        <img src='/images/BackButton.svg' className='img-fluid cursor-pointer ps-2' style={{ height: "15px" }} onClick={() => handleBackButtonClick('Building')} />
                                                    </button>
                                                </div>
                                            )}

                                        </div>
                                    )}

                                </div>
                            </div>
                        </>
                    }


                </div>

                {!Buildingloading && (
                    <div className={`col-md-12 pt-2 ${RoomData.ShowRoomDiv}`}>
                        <div className='row py-3'>
                            <div className="col-12">
                                <div className='col-md-7 questionchatbox'>
                                    <img src='/images/GreenSmallLogo.svg' className='img-fluid questionlogo' />
                                    Which room do you want to audit?
                                </div>
                            </div>
                        </div>
                        <div className='col-12 '>
                            {!RoomData?.RoomName ? (
                                <div className={`ps-0 d-flex flex-wrap`}>
                                    {RoomData.AllRooms?.map((item, i) => (
                                        <div className='py-2 align-items-center mx-2' key={i} >
                                            <button className="helpDesk-button" onClick={() => handleRoomButtonClick(item.id)}>
                                                {item.name}
                                            </button>

                                        </div>
                                    ))}
                                    <div className='py-2 align-items-center mx-2'>
                                        <button className="helpDesk-button" onClick={() => handleRoomButtonClick("all")}>
                                            Entire Building
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {RoomLoading ? (
                                        <div className='float-start mt-3'>
                                            <Dotsloder />
                                        </div>
                                    ) : (
                                        <div className='float-end'>
                                            <button className={`helpDesk-button active px-3 py-2 cursor-pointer`}>
                                                <span className=''>{RoomData.RoomName}</span>
                                                <img src='/images/BackButton.svg' className='img-fluid cursor-pointer ps-2' style={{ height: "15px" }} onClick={() => handleBackButtonClick('Room')} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {
                    !RoomLoading && !Buildingloading && RoomData.RoomId != 0 && BuildingData.BuildingId != 0 && (
                        <div className='text-center' style={{ marginTop: '15%' }}>
                            <h5 style={{ fontWeight: "500" }} className='schoolColorStatus'>
                                {BuildingData.BuildingId == "all" && RoomData.RoomId == "all" ?
                                    <label>
                                        Selecting 'All Buildings' or 'Entire Building' will disable location-based reporting functionality.
                                    </label>
                                    :
                                    <label>
                                        You have selected <b>{RoomData.RoomName}</b> from <b>{BuildingData.BuildingName}</b>.
                                    </label>
                                }
                            </h5>
                            <div className='pb-0'>
                                <button className='SaveBtn mt-3'
                                    onClick={(e) => { setVisibilityDiv(2) }}>
                                    Start Audit
                                </button>
                            </div>
                        </div>
                    )
                }
            </div >
        </>
    )
}
