import React from 'react'

export default function HdRequestData(props) {
    var PopupData = props.data;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    return (
        <div>
            <div className='row px-2'>
                <div className='col-12 pb-2'>
                    <label>Request Number : </label>&nbsp;<b>{PopupData?.request_num}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Requester : </label>&nbsp;<b>{PopupData?.requester_type == 1 ? 'Student / Parent' : 'Teacher'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Helpdesk Type : </label>&nbsp;<b>{PopupData?.additional_info?.typeName}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Category : </label>&nbsp;<b>{PopupData?.additional_info?.categoryName || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Subcategory : </label>&nbsp;<b>{PopupData?.additional_info?.subcategoryName || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Issue : </label>&nbsp;<b>{PopupData?.additional_info?.issueName || '-'}</b>
                </div>
                <div className='col-12 py-2'>
                    <b className='h4'>Ticket Contact Details</b>
                </div>
                <hr className='mb-0' />
                <div className='col-12 py-2'>
                    <label>Subject : </label>&nbsp;<b style={{ wordWrap: "break-word" }}>{PopupData?.title || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Name : </label>&nbsp;<b>{PopupData?.name || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Email : </label>&nbsp;<b>{PopupData?.email || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Room No. : </label>&nbsp;<b>
                        {PopupData?.room_name ? PopupData?.room_name : PopupData?.room ? PopupData?.room?.name : '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Building : </label>&nbsp;<b>{PopupData?.building_name ? PopupData?.building_name : PopupData?.building?.name}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Student No. : </label>&nbsp;<b>{PopupData?.student_no || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Assigned to : </label>&nbsp;<b>{PopupData?.additional_info?.assignedtoname || '-'}</b>
                </div>
                <div className='col-12 pb-2'>
                    <label>Attached Image  : </label>&nbsp;
                    {PopupData?.document ?
                        <img src={`${BucketS3Link}${PopupData?.document}`} style={{ maxHeight: '100px', maxWidth: '100%' }} />
                        :
                        <>-</>
                    }
                </div>
                <div className='col-12 pb-2'>
                    <label>Attached Video  : </label>&nbsp;
                    {PopupData?.video ?
                        <iframe
                            width="200"
                            height="100"
                            src={`${BucketS3Link}${PopupData?.video}`}
                            frameborder="0"
                            allowfullscreen

                        ></iframe>
                        :
                        <>-</>
                    }
                </div>
                <div className='col-12 pb-2'>
                    <label>Description : </label>&nbsp;<b>{PopupData?.description || '-'}</b>
                </div>
            </div>
        </div>
    )
}
