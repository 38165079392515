import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogInSchoolID, getAllBuildings, getAllUsers, typewatch } from '../../JS/Common';
import { useEffect } from 'react';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import Pagination from 'react-js-pagination';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';
import Deletepopup from '../../Components/Deletepopup';
function TicketAssignment() {
    const [loading, setLoading] = useState(false);
    const [Ispopup, setIspopup] = useState(false);
    const [Users, setUsers] = useState([]);
    const [BuildingData, setBuildingData] = useState([]);
    const [GridData, setGridData] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [ErrorMsg, setErrorMsg] = useState('');
    const [SearchString, setSearchString] = useState('');
    const [flag, setFlag] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [DeletePopup, setDeletePopup] = useState(false);
    const [DeleteAssignmentId, setDeleteAssignmentId] = useState('');
    const [selectedIds, setSelectedIds] = useState({
        buildingId: 0,
        CategoryId: 0,
        staffMemberId: 0,
        selectedBuildingNm: '',
        SelectedId: ''
    })
    const showPagination = GridData.length > 0;
    const navigate = useNavigate();
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    useEffect(() => {
        GetGridData(SearchString ? SearchString : null);
        getAllBuildings(setBuildingData, LogInSchoolID);
        getAllUsers(setUsers)
    }, [currentPage, LimitForPagination]);
    async function GetGridData(searchkey) {
        if (!searchkey) searchkey = null;
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/getAllSupportTicketAssignment/" + LogInSchoolID + '&' + searchkey + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.msg.total)
                    setGridData(responseRs.msg.data.length ? responseRs.msg.data : []);
                }
            });
        }, 500);
    }
    async function GetElementByid(id) {
        setIspopup(true)
        setLoading(true);
        await ApiGetCall("/getSupportTicketAssignmentByID/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                setSelectedIds({ ...selectedIds, buildingId: responseRs.msg.building_id, SelectedId: responseRs.msg.id, selectedBuildingNm: responseRs.msg.buildingName, CategoryId: responseRs.msg.category_id, staffMemberId: responseRs.msg.staff_member_id })
            }
        });
    }
    async function handleSaveUpdate(flag) {
        var raw = JSON.stringify({
            LogInSchoolID: LogInSchoolID,
            BuildingId: selectedIds.buildingId,
            CategoryId: selectedIds.CategoryId,
            StaffMemberId: selectedIds.staffMemberId,
            Flag: flag,
            ID: selectedIds.SelectedId
        });
        if (flag == 1) {
            if (selectedIds.buildingId == 0 || selectedIds.CategoryId == 0 || selectedIds.staffMemberId == 0) {
                setErrorMsg('select at all Option')
                return
            }
        }
        if (flag == 2) {
            if (selectedIds.CategoryId == 0 || selectedIds.staffMemberId == 0) {
                setErrorMsg('select at all Option')
                return
            }
        }
        await ApiPostCall("/addUpdateSupportTicketAssignment", raw).then((result) => {
            const responseRs = JSON.parse(result);
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (responseRs.status == "success") {
                    var msg = flag == 1 ? 'Ticket Assignments Added Successfully' : 'Ticket Assignments Updated Successfully';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setSelectedIds({ ...selectedIds, buildingId: 0, CategoryId: 0, staffMemberId: 0 })
                    setIspopup(false)
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetGridData(null);
                    }, 1500);
                }
                else if (responseRs.status == "error") {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetGridData(null);
                    }, 1500);
                }
            }
        });
    }
    function handleSearchChange(e) {
        setSearchString(e.target.value);
        setCurrentPage(1);
        GetGridData(e.target.value);
    }
    const GroupInfoModalBody = (
        <section>
            <div className="p-3" i >
                <span className="inline-label">Tickets generated for Building</span>
                {flag == 1 ?
                    <select className="form-select custom-width inline-dropdown mx-2" value={selectedIds.buildingId} onChange={(e) => setSelectedIds({ ...selectedIds, buildingId: e.target.value })}>
                        <option value='0'>Select Building</option>
                        {BuildingData.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    :
                    <span className="inline-label px-2"><b>{selectedIds.selectedBuildingNm}</b></span>
                }
                <span className="inline-label">under </span>
                <select className="form-select custom-width inline-dropdown mx-2" value={selectedIds.CategoryId} onChange={(e) => setSelectedIds({ ...selectedIds, CategoryId: e.target.value })}  >
                    <option value='0'>Select Category</option>
                    <option value="1">Technology</option>
                    <option value="2">Maintenance</option>
                </select>
                <div className="py-3">
                    <span className="inline-label">Category should be assigned to </span>&nbsp;
                    <select
                        className="form-select custom-width inline-dropdown" value={selectedIds.staffMemberId} onChange={(e) => setSelectedIds({ ...selectedIds, staffMemberId: e.target.value })} >
                        <option value='0'>Select Staff Member</option>
                        {Users.map((staffMember) => (
                            <option key={staffMember.id} value={staffMember.id}>
                                {staffMember.name}
                            </option>
                        ))}
                    </select>
                </div>

            </div>
            <div className='ps-3' style={{ color: 'red' }}>{ErrorMsg}</div>
        </section>
    );
    async function DeleteTicketAssignment() {
        setLoading(true);
        var raw = "";
        await ApiPostCall("/deletesupportticketassignment/" + LogInSchoolID + "&" + DeleteAssignmentId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setDeletePopup(false);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Assignment Deleted Successfully."} />);
                    setTimeout(() => {
                        GetGridData(null);
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Ticket Assignments"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <section>
                <div className='row align-items-center pe-0'>
                    <div className='col-md-12 py-2 text-end'>
                        <label className='BorderBtn' onClick={(e) => { setFlag(1); setIspopup(true) }} title='Assign Building to Staff Member'>
                            <img src='/images/AddInventory.svg' className='img-fluid px-2' />Assign Building to Staff Member
                        </label>
                        <label className='BorderBtn text-end' onClick={(e) => navigate('/staff-members')} title='Edit Staff Member'>
                            <img src='/images/EditIconNew.svg' className='img-fluid px-2' /> Edit Staff Member
                        </label>
                    </div>
                </div>
            </section>
            <section>
                <div className="GridBox p-3" >
                    <div className='greyBox p-3'>
                        <div className='row row-cols-1 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 g-4 g-lg-4'>
                            {GridData.length != 0 ? (
                                GridData.map((item, i) => {
                                    return <div className='col' >
                                        <div className='p-3 GridBox boxshaowstyle'>
                                            <img src="/images/NewEditIconn.svg" className="cursor-pointer" title='Edit Ticket Assignments' style={{ float: 'right' }} height='16px' onClick={(e) => { setFlag(2); GetElementByid(item.assignmentID) }} />
                                            <img src="/images/DeleteIcon.svg" title="Delete Ticket Assignment" className="img-fluid cursor-pointer pe-2" style={{ float: 'right' }} height='16px'
                                                onClick={(e) => { setDeletePopup(true); setDeleteAssignmentId(item.assignmentID) }} />
                                            <h4 className='mb-0 mt-1 fw-600' style={{ color: '#2cc3a9', fontSize: '24px' }}>{item.buildingName}</h4>
                                            {item.category_id == 1 ? (
                                                <div className='fw-600 mt-3' style={{ fontSize: '16px', color: '#4C5258' }}>Technology</div>
                                            ) : (
                                                <div className='fw-600 mt-3' style={{ fontSize: '16px', color: '#4C5258' }}>Maintenance</div>
                                            )}
                                            <div className='mt-3'>{item.avtarPath != null ? <img src={`${BucketS3Link}avtar/${item.avtarPath}`} height='28px' /> : '-'}&nbsp;&nbsp;<span style={{ fontWeight: '400', fontSize: '16px', fontWeight: '600', color: '#4C5258' }}>{item.assignToName}</span></div>
                                        </div>
                                    </div>
                                })
                            ) : <div className='text-center m-auto' style={{ alignItems: 'center' }}>
                                <img src='/images/TicketAssignmentIcon.svg' />
                            </div>
                            }
                        </div>
                    </div>

                    {showPagination && (
                        <Pagination
                            activePage={currentPage}
                            totalItemsCount={pageRangeCount}
                            pageRangeDisplayed={15}
                            itemsCountPerPage={LimitForPagination}
                            onChange={(e) => setCurrentPage(e)}
                            color="primary"
                            lastPageText=""
                            firstPageText=""
                            itemClassFirst="MasterClass"
                            itemClassPrev="MasterClass"
                            itemClassNext="MasterClass"
                            itemClassLast="MasterClass"
                        />
                    )}
                </div>
            </section>
            <Deletepopup text={`Are you sure you want to delete this ticket assignment?. You won't be able to revert this!`} deletePopup={DeletePopup} size="xl" deletePart={DeleteTicketAssignment} DeleteShowClose={(e) => setDeletePopup(false)} />

            <Popup isshow={Ispopup} size={"lg"} title={flag == 1 ? 'Add Ticket Assignments' : 'Edit Ticket Assignments'}
                closePopup={(e) => { setIspopup(false); setSelectedIds({ ...selectedIds, buildingId: 0, CategoryId: 0, staffMemberId: 0 }) }}
                modalBody={GroupInfoModalBody} handleSave={(e) => flag == 1 ? handleSaveUpdate(1) : handleSaveUpdate(2)} visibleSaveBtn={true} btnText={flag == 1 ? 'Add' : 'Update'} />
        </React.Fragment>
    )
}

export default TicketAssignment
