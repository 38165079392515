import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { ErrorPage } from '../404Error';
import { FaqIndex } from '../FAQ';
import { ManageTicket } from '../ManageTicket';
import { CreateTicket } from '../CreateTicket';
import RepairDetails from '../../Components/RepairDetails';
import { ManageDeviceIndex } from '../ManageDevice';
import { AddUpdate } from '../ManageDevice/addupdate';
import { TechnologySupportTicket } from '../SupportTicket/Technology';
import { MaintenanceSupportTicket } from '../SupportTicket/Maintenance';
import Helpdeskrequest from '../Helpdesk/Helpdeskrequest';
import DeviceCustomField from '../CustomField/Index';
import { ImportExport } from '../ImportExport';

export default function Access14() {
    return (
        <Routes>
            <Route path="/" element={<ManageTicket />} />
            <Route exact path="/manage-tickets/*" element={<ManageTicket />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route path="/manage-device/*" element={<ManageDeviceIndex />} />
            <Route path="/addupdate-device/*" element={<AddUpdate />} />
            <Route exact path="/technology-support-tickets" element={<TechnologySupportTicket />} />
            <Route exact path="/maintenance-support-tickets" element={<MaintenanceSupportTicket />} />
            <Route exact path="/helpdesk-requests" element={<Helpdeskrequest />} />
            <Route exact path="/custom-field" element={<DeviceCustomField />} />
            <Route exact path="/import-export" element={<ImportExport />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
        </Routes>
    )
}
