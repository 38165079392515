import React, { useState, useContext, useEffect } from 'react';
import { TableDataContext } from '../index';
import { Camelcase } from '../../../JS/Common';
import Cookies from "js-cookie";
import { UserContext } from '../../../App';

export default function SupportTicketTable() {
    const { AllDashBoardData } = useContext(TableDataContext);
    const [ticketStatus, setTicketStatus] = useState('open');
    const [loadData, setLoadData] = useState({ technologyTickets: [], maintenanceTickets: [], show: false });
    const { UserData } = useContext(UserContext);

    useEffect(() => {
        const updateLoadData = (tickets, type) => {
            const data = tickets.length > 20 ? tickets.slice(0, 20) : tickets;
            setLoadData(prevState => ({
                ...prevState,
                [type]: data
            }));
        };

        if (AllDashBoardData?.SupportTickets?.data?.technologyTickets?.length > 0) {
            updateLoadData(AllDashBoardData.SupportTickets.data.technologyTickets, 'technologyTickets');
        }

        if (AllDashBoardData?.SupportTickets?.data?.maintenanceTickets?.length > 0) {
            updateLoadData(AllDashBoardData.SupportTickets.data.maintenanceTickets, 'maintenanceTickets');
        }
    }, [AllDashBoardData]);

    const loadMoreTickets = () => {
        const updateTickets = (currentTickets, allTickets) => {
            const newTickets = currentTickets.concat(
                allTickets.slice(currentTickets.length, currentTickets.length + 20)
            );
            return newTickets.length === allTickets.length ? allTickets.slice(0, 20) : newTickets;
        };

        if (ticketStatus === 'open') {
            const newTechnologyTickets = updateTickets(loadData.technologyTickets, AllDashBoardData.SupportTickets.data.technologyTickets);
            setLoadData(prevState => ({ ...prevState, technologyTickets: newTechnologyTickets }));
        } else {
            const newMaintenanceTickets = updateTickets(loadData.maintenanceTickets, AllDashBoardData.SupportTickets.data.maintenanceTickets);
            setLoadData(prevState => ({ ...prevState, maintenanceTickets: newMaintenanceTickets }));
        }
    };

    const generateUrl = (ticketNum, hdId) => {
        const schoolnumber = Cookies.get("Schoolnumber");
        const path = hdId ? `/helpdesk-ticket/${schoolnumber}/${hdId}` : `/ticket/${schoolnumber}/${ticketNum}`;
        const url = `${window.location.origin}${path}`;
        const newTab = window.open('', '_blank');
        newTab.location.href = url;
        newTab.focus();
    };

    const renderTickets = (tickets) => (
        tickets.length > 0 ? tickets.map((item, i) => (
            <tr className='OddEvenClass tableBorderHide py-3' key={item.id}>
                <td className="py-12">{item.title}</td>
                <td className="py-12">{item.name}</td>
                <td className="py-12">
                    {item.assigned_to ?
                        item.user ?
                            <div className="location-name">{Camelcase(item.user.first_name)} {Camelcase(item.user.last_name)}</div> :
                            <div className="location-name">{item.user_group.name}</div> :
                        <div className="location-name">-</div>
                    }
                </td>
                <td className="text-center">
                    <img src="/images/previewpdficon.svg" className="cursor-pointer px-1" title="Preview Support Tickets" onClick={() => generateUrl(item.support_ticket_num, item.hd_request_id)} height='20px' />
                </td>
            </tr>
        )) : (
            <tr className='OddEvenClass tableBorderHide py-3'>
                <td colSpan={4} className='text-center'>No Record Found</td>
            </tr>
        )
    );

    return (
        <React.Fragment>
            <div className="col-12 greyBoxShadow" style={{ height: "100%" }}>
                <div className="w-100">
                    <div className="row mt-2 px-2 time">
                        <div className='Header'>
                            <b className='font-15'>{UserData.helpdeskUpdatedFlag != null ? 'Helpdesk Open Tickets' : 'Support Open Tickets'}</b><br />
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="px-3">
                        <div className="row py-2">
                            <div className="col-6">
                                <input className="form-check-input me-1" value="open" type="radio" name='SupportTicket' checked={ticketStatus === "open"} onChange={(e) => setTicketStatus(e.target.value)} />
                                <label className="form-check-label font-13 fw-bolder">Technology</label>
                            </div>
                            <div className="col-6">
                                <input className="form-check-input me-1" value="close" type="radio" name='SupportTicket' checked={ticketStatus === "close"} onChange={(e) => setTicketStatus(e.target.value)} />
                                <label className="form-check-label font-13 fw-bolder">Maintenance</label>
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    {AllDashBoardData?.SupportTickets?.loader ? (
                        <div className='row'>
                            <div className="col-12 text-center pt-2">
                                <img src="/images/loder.svg" style={{ width: "30px" }} alt="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600 font-14'>Title</th>
                                    <th scope="col" className='fw-600 font-14'>Name</th>
                                    <th scope="col" className='fw-600 font-14'>Assigned</th>
                                    <th scope="col" className='fw-600 font-14 text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketStatus === "open" ? renderTickets(loadData.technologyTickets) : renderTickets(loadData.maintenanceTickets)}
                            </tbody>
                        </table>
                    )}
                </div>
                {ticketStatus === "open" && AllDashBoardData?.SupportTickets?.data?.technologyTickets?.length > 20 && (
                    <div className="col-12 p-2 admin-table-border-top">
                        <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={loadMoreTickets}>
                            {loadData.technologyTickets.length === AllDashBoardData.SupportTickets.data.technologyTickets.length ? "Hide" : "Load More"}
                        </span>
                    </div>
                )}
                {ticketStatus === "close" && AllDashBoardData?.SupportTickets?.data?.maintenanceTickets?.length > 20 && (
                    <div className="col-12 p-2 admin-table-border-top">
                        <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={loadMoreTickets}>
                            {loadData.maintenanceTickets.length === AllDashBoardData.SupportTickets.data.maintenanceTickets.length ? "Hide" : "Load More"}
                        </span>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
