import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { MaintenanceSupportTicket } from '../SupportTicket/Maintenance';
import Helpdeskrequest from '../Helpdesk/Helpdeskrequest';
import { ErrorPage } from '../404Error';
import { FaqIndex } from '../FAQ';
export default function Access9() {
    return (
        <Routes>
            <Route exact path="/" element={<MaintenanceSupportTicket />} />
            <Route exact path="/maintenance-support-tickets" element={<MaintenanceSupportTicket />} />
            <Route exact path="/helpdesk-requests" element={<Helpdeskrequest />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
