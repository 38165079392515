import React, { useRef, useState } from 'react'
import LoadingBar from 'react-top-loading-bar';
import { LogInSchoolID } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function ImportHelpdeskItems() {
    const fileRef = useRef();
    const refForWithUser = useRef();
    const [NewAlerts, setNewAlerts] = useState(false);
    const [fileName, setFileName] = useState('');
    var authkey = process.env.REACT_APP_AuthKey;
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const navigate = useNavigate();
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", authkey);

    function ShowFileName() {
        const fileRefvar = fileRef.current;
        const files = fileRefvar.files[0];
        const filename = files.name;
        setFileName(filename);
    }
    async function ImportCSV(e, flag) {
        var temp = 0;
        e.preventDefault();
        var formdata = new FormData();
        const fileInput = fileRef.current;
        const files = fileInput.files[0];
        if (files == undefined) {
            setFileName("First, import the CSV file.");
        } else {
            temp++;
            const filename = files.name;
            var extension = filename.split('.').pop();

            if (extension == "csv") {
                setFileName(filename);
            }

            formdata.append("file", files);
            formdata.append("schoolId", parseInt(LogInSchoolID));
        }
        if (temp == 1) {
            const loaderRef = refForWithUser.current;
            if (loaderRef) {
                loaderRef.continuousStart();
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };
            try {
                const response = await fetch(`${BaseUrl}/ImportHelpDesk`, requestOptions);
                const result = await response.text();
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/helpdesk')
                    }, 1500);

                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            } catch (error) {
                console.log('error', error);
            }
            finally {
                if (loaderRef) {
                    loaderRef.complete();
                }
            }
        }
    }
    return (
        <div>
            {NewAlerts}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Import Helpdesk Items</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    The system matches issues with existing Helpdesk categories. If a match exists, it will be used without creating duplicates. New categories or issues will not be created during CSV import. Ensure entries align with existing Helpdesk types.
                </p>

                <div className="row pt-1">
                    <div className="col-12">
                        <label className="pb-4 ps-2 text-justify" style={{ fontSize: "13px" }}>
                            <a href="/Csv/Helpdesk_items.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for helpdesk items. Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account. </label>
                        <form onSubmit={(e) => ImportCSV(e, 1)}>
                            <input type="file" ref={fileRef} name="upload_file" id="UploadFileId" accept='.csv' onChange={ShowFileName} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="UploadFileId"> Import Helpdesk Items
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {fileName != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                            <br />
                            <div className=" ps-2 my-2" style={{ fontWeight: "600", color: 'green' }}>{fileName}</div>
                        </form>
                        <LoadingBar style={{ borderRadius: "6px" }} color='#3cbba5' ref={refForWithUser} containerClassName='progressbarClass' />
                    </div>
                </div>
            </div>
        </div>
    )
}
